import TableReceiptCheckingDetail from '@/views/home/receipt-checking/detail/TableReceiptCheckingDetail.vue'
import {
  deliveryManifestUseCase,
  receiptCheckingUseCase,
  checkingResiUseCase
} from '@/domain/usecase'
import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import moment from 'moment'
import {
  useRoute,
  useRouter
} from 'vue-router'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'

export default {
  name: 'ReceiptCheckingDetail',
  components: {
    TableReceiptCheckingDetail
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const route = useRoute()
    const router = useRouter()
    const showDialogDetail = ref(false)
    const idDetail = ref(null) as any
    const refTableReceiptCheckingDetail = ref()
    const dataHeader = ref({}) as any
    const showTableDetail = ref(false)
    const inputScanResi = ref(null) as any
    const refInputScanResi = ref()
    const decryptData = () => encryptDecriptMethods.decrypt(route.params.id)
    const getHeader = async () => {
      const hasilDec = await decryptData()
      const response = await checkingResiUseCase.getSpesificData(Number(hasilDec))
      // console.log('repponponp', response)
      // const response = await deliveryManifestUseCase.getOneDetail(Number(hasilDec), {
      //   expand: '&$expand=TipeBus($select=Kode), CloseBy($select=Id,NamaLengkap)',
      //   select: '&$select=Id,KodeJadwal,Rit,TanggalAktif,TanggalNonAktif,IsClose,CloseDate'
      // })
      if (!response.error) {
        dataHeader.value = await response.result
        showTableDetail.value = true
      } else {
        $toast.add({
          severity: 'error',
          // detail: $strInd.toast.errorAllData,
          detail: response.message,
          group: 'bc',
          life: 1500
        })
      }
    }

    const checkResponse = (response: any) => {
      // console.log('response after put', response)
      if (!response.error) {
        $toast.add({
          severity: 'success',
          summary: response.result.title ?? response.result.Title,
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 3000
        })
        router.push({
          name: 'receipt-checking'
        })
      } else {
        $toast.add({
          severity: 'error',
          // summary: response.result.title,
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      }
    }

    const processCloseSchedule = async () => {
      const sendData = {
        Id: Number(dataHeader.value.Id),
        UangDiterima: refTableReceiptCheckingDetail.value.totalReceived,
        Keterangan: refTableReceiptCheckingDetail.value.descriptionTableFinished
      }
      const response = await receiptCheckingUseCase.closingSchedule(Number(dataHeader.value.Id), sendData)
      checkResponse(response)
    }

    const openModalDetail = (val: any) => {
      idDetail.value = val
      showDialogDetail.value = true
    }

    const searchResi = (val: any) => {
      if (val.length === 36) {
        // router.push({ name: 'receipt-checking-params', params: { id: val } })
        openModalDetail(val)
      }
    }

    const hideDialogDetail = async (val: any) => {
      // console.log('okek', val)
      if (val) {
        refTableReceiptCheckingDetail.value.processChangeValRowWithIndex(val.StatusPengecekan, val.Id, val.KeteranganCheckScan)
        getHeader()
      }
      showDialogDetail.value = false
      // await router.push({
      //   name: 'receipt-checking'
      // })
      inputScanResi.value = null
      refInputScanResi.value.$el.focus()
    }

    const showDetailResi = (val: any) => {
      openModalDetail(val)
    }

    const submitData = async () => {
      const canSubmit = await refTableReceiptCheckingDetail.value.isCanSubmit()
      // console.log('masuk can submit', canSubmit)
      if (canSubmit) {
        $confirm.require({
          header: 'Tutup Jadwal Ini ?',
          message: 'Pastikan jadwal ini sudah dilakukan cek keuangan, resi dan label untuk meminimalisir kesalahan. Setelah ditutup, jadwal tidak bisa diubah lagi.',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Simpan',
          rejectLabel: 'Kembali',
          accept: () => {
            processCloseSchedule()
          }
        })
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Pastikan Semua Resi Sudah Di Scan dan Status Tidak Ada Yang Pending',
          group: 'bc',
          life: 3000
        })
      }
    }

    const reloadFromDialog = async () => {
      await getHeader()
      refTableReceiptCheckingDetail.value.getAllTable()
    }

    onMounted(() => {
      getHeader()
    })
    return {
      dataHeader,
      moment,
      route,
      decryptData,
      showTableDetail,
      refTableReceiptCheckingDetail,
      submitData,
      getHeader,
      searchResi,
      inputScanResi,
      showDialogDetail,
      idDetail,
      hideDialogDetail,
      refInputScanResi,
      showDetailResi,
      reloadFromDialog
    }
  }
}
