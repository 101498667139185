<template>
  <div>
    masukkkkk compo
  </div>
</template>

<script>
export default {
  props: {
    cellData: {
      type: Object
    }
  },
  data() {
    return {

    }
  },
  mounted() {
    console.log('yeys god')
  }
}
</script>
