
import {
  ref,
  onMounted,
  reactive,
  getCurrentInstance,
  computed
} from 'vue'
import {
  useStore
} from 'vuex'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import MultiselectAsync from '@/views/shared-components/select/MultiselectAsync.vue'
import { claimUseCase, receiptCheckingUseCase } from '@/domain/usecase'

export default {
  name: 'TableReceiptCheckingDetail',
  components: {
    Form,
    Field,
    MultiselectAsync
  },
  props: {
    receiptData: {
      default: {}
    },
    typeForm: {
      default: null
    }
  },
  emits: ['reloadHeader', 'hideModal', 'processChangeValRowWithIndex'],
  setup(props: any, { emit }: any) {
    const propertiesProps = reactive(props)
    const receiptData = computed(() => propertiesProps.receiptData) as any
    const typeForm = computed(() => propertiesProps.typeForm) as any
    const app = getCurrentInstance()
    const receiptLabels = ref([]) as any
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const valueDescription = ref('')
    const dataForm = ref({
      Nominal: 0,
      Agent: null,
      Note: null
    })

    const schemaForm = Yup.object({
      Nominal: Yup.string().required('Nominal harus diisi.').min(0, 'Nominal tidak boleh dibawah 0.'),
      Agent: Yup.mixed().required('Agen harus diisi.'),
      Note: Yup.string().required('Keterangan harus diisi.')
    })

    const hideModal = () => {
      emit('hideModal')
    }

    const reloadheader = () => {
      emit('reloadHeader')
    }

    const processChangeValRowWithIndex = (typeForm: any, typeFormId: any, notes: any) => {
      emit('processChangeValRowWithIndex', typeForm, typeFormId, notes)
    }

    const getReceiptLabels = async () => {
      store.dispatch('showLoading')
      const response = await receiptCheckingUseCase.getReceiptLabels(receiptData.value.Id)
      if (!response.error) {
        receiptLabels.value = response.result
      }
      store.dispatch('hideLoading')
    }

    const onSubmit = (values: any) => {
      console.log('submit', values)
      const payload = {
        type: typeForm.value.Id === 3 ? 'LostWaybill' : 'LostLabel',
        references: [],
        agentInChargeId: values.Agent.Id,
        amount: values.Nominal,
        recipients: [] as any
      }

      // const labels = receiptLabels.value.map((data: any) => `${data.Code}${data.Color}`)
      // payload.reference = typeForm.value.Id === 3 ? receiptData.value.Resi : labels.join(',')

      // const agentObj = {
      //   userId: values.Agent.Id,
      //   email: null,
      //   phoneNumber: null,
      //   name: values.Agent.Name,
      //   amount: values.Nominal
      // }
      // payload.recipients.push(agentObj)

      const labels = [] as any
      let obj = {} as any
      if (typeForm.value.Id === 3) {
        obj = {
          externalId: receiptData.value.Id,
          name: receiptData.value.Resi,
          attributes: []
        }
        labels.push(obj)
      } else {
        // eslint-disable-next-line array-callback-return
        receiptLabels.value.map((data: any) => {
          obj = {
            externalId: data.Id,
            name: data.Code,
            attributes: [
              {
                type: 'COLOR',
                value: data.Color
              }
            ]
          }
          labels.push(obj)
        })
      }
      payload.references = labels

      const agentObj = {
        userId: values.Agent.Id,
        email: null,
        phoneNumber: null,
        name: values.Agent.Name,
        amount: values.Nominal
      }
      payload.recipients.push(agentObj)

      console.log('payload', payload)
      $confirm.require({
        header: 'Proses Tambah Klaim',
        message: 'Apakah anda yakin ingin melakukan proses tambah klaim, lanjutkan ?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const dataSend = {
            Id: receiptData.value.Id,
            StatusPengecekanId: typeForm.value.Id,
            KeteranganCheckScan: values.Note
          }
          console.log('datasend', dataSend)
          const responseStatusReceipt = await receiptCheckingUseCase.editPengecekanResiCheckResi(dataSend)
          if (responseStatusReceipt.error) {
            $toast.add({
              severity: 'error', detail: responseStatusReceipt.message ?? $strInd.toast.errorSubmit, group: 'bc', life: 3000
            })
          } else {
            processChangeValRowWithIndex(typeForm.value, receiptData.value.Id, values.Note)
            const response = await claimUseCase.submitDataPenalty(payload)
            if (!response.error) {
              hideModal()
              reloadheader()
              $toast.add({
                severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
              })
            } else {
              $toast.add({
                severity: 'error', detail: response.message ?? $strInd.toast.errorSubmit, group: 'bc', life: 3000
              })
            }
          }
          store.dispatch('hideLoading')
        }
      })
    }

    onMounted(() => {
      console.log('props', receiptData.value)
      getReceiptLabels()
    })

    return {
      valueDescription,
      dataForm,
      schemaForm,
      receiptLabels,
      onSubmit,
      hideModal
    }
  }
}
